@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
@media (min-width: 1200px) {

}

.profile-section-class{
  color: rgb(170,181,193);
  font-weight: bold;
  border: 1px solid rgb(170,181,193);
  border-width: 0 0 1px;
  margin: 0 40px;
  padding: 1em 0;
}
.basic-information.no_margin_bottom{
  margin-bottom:0;
}
.form-group.no_margin_bottom{
  margin-bottom:0;
}