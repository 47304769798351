.product-item {
  padding: 14px 14px;
  height: 48px;
  border-radius: 5px;
  background-color: #1F51A2;
  color: #fff;
  font-size: 0.8em;
  margin-right: 8px;
  margin-bottom: 8px;
}

.die-bestellung-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.die-bestellung-subtitle {
  display: flex;
  max-width: 50%;
  justify-content: center;
  color: rgb(96,103,110);
  padding-bottom:30px;
  font-size: 1.3em;
  margin-right: auto;
  margin-left: auto;
}