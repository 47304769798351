@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
@media (min-width: 1200px) {

}

.profile-section-class{
  color: rgb(170,181,193);
  font-weight: bold;
  border: 1px solid rgb(170,181,193);
  border-width: 0 0 1px;
  margin: 0 40px;
  padding: 1em 0;
}
.basic-information.no_margin_bottom{
  margin-bottom:0;
}
.form-group.no_margin_bottom{
  margin-bottom:0;
}
.product-item {
  padding: 14px 14px;
  height: 48px;
  border-radius: 5px;
  background-color: #1F51A2;
  color: #fff;
  font-size: 0.8em;
  margin-right: 8px;
  margin-bottom: 8px;
}

.die-bestellung-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.die-bestellung-subtitle {
  display: flex;
  max-width: 50%;
  justify-content: center;
  color: rgb(96,103,110);
  padding-bottom:30px;
  font-size: 1.3em;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 575.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}
@media (min-width: 1200px) {
  .summary-menu {
    width: 200px;
    height: auto;
  }
  .summary-menu-item {
    width: 200px;
    height: 120px;
  }
}

.circle-with-badge {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  border-radius: 50%;
  min-width: 130px;
  width: 130px;
  height: 130px;
  padding: 0;
  text-align:center;
  background: white;
}

.circle-with-badge-bottom-label {
  color: rgb(96,103,110);
}

.custom-circle {
  display: flex;
  align-items: center;
  margin: 0;
  width: 170px;
}

.custom-circle-icon {
  height: 86px;
}

.custom-circle-big-icon {
  height: 124px;
}

.custom-circle-right-label {
  font-size: 2em;
  line-height: 36px;
  font-weight: bold;
  color: #28a745;
}

.custom-big-circle {
  min-width: 180px;
  height: 180px;
}

.carousel-control-area {
  width: 50px;
  opacity: 1;
}

.carousel-section {
  padding: 60px 90px;
}

.section-title {
  font-size: 2em;
  color: rgb(96,103,110);
  padding-top: 24px;
  padding-bottom: 12px;
}

.section-item {
  font-size: 1em;
  color: rgb(96,103,110);
  padding-bottom: 16px;
}

.section-item-disabled {
  opacity: 0.5
}

.summary-menu-item {
  border: 1px solid rgb(170,181,193);
  border-width: 0 0 1px 1px;
  outline: none;
  cursor: pointer;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  text-align: center;
}

.active-menu-button {
  background-color: #1F51A2;
  color: #ffffff !important;
}

.summary-menu-label {
  color: #1F51A2;
  text-align: center;
}

.blue-icon {
  color: #1F51A2 !important;
}

.white-icon {
  color: #ffffff;
}

.grey-icon {
  color: rgb(96,103,110);
  opacity: 0.8
}

.green-icon {
  color: #28a745!important;
}

.limon-icon {
  color: #AFD541;
}

.orange-icon {
  color: #F5821F;
}

.summary-offer-toolbar {
  display: flex;
  width: 100%;
  padding: 13px 15px;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  background-color: #fff;
  margin-right: 80px;
}

.summary-offer-progress-label {
  font-size: 1em;
  font-weight: bold;
  color: #1F51A2;
  padding-right: 15px;
}

.summary-offer-progress {
  width: 30%
}

.summary-offer-progress-bar {
  background-color: #1F51A2;
}

.summary-offer-button {
  width: 186px;
  margin-right: 5px;
  margin-left: 5px;
  font-weight: bold;
}

.summary-offer-icon {
  margin-left: 5px;
}

.video-wrapper {
  padding: 52px;
}

.language-pack-box {
  position: relative;
  width: 23%;
  padding-top: 64px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 48px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  min-width:184px;
  max-width:250px;
}

.language-pack-box-selected {
  border: 2px solid #28a745;
  color: #1F51A2;
}

.language-pack-box-selected-label {
  width: calc(100% + 4px);
  height: 36px;
  font-weight: bold;
  font-size: 1em;
  background-color: #28a745;
  border: 2px solid #28a745;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  left: -2px;
  top: -2px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-pack-box-label {
  color: rgb(96,103,110);
  width: 140px;
  text-align: center;
}

.language-pack-box-price {
  font-size: 2em;
  font-weight: bold;
  color: rgb(96,103,110);
  text-align: center;
  margin-top: auto;
}

.language-pack-check-box {
  padding-right: 8px;
}

.additional-option-pack-box {
  position: relative;
  width: 18%;
  padding-top: 64px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 48px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  min-width:148px;
  max-width:250px;
}

.additional-option-pack-box-selected {
  border: 2px solid #28a745;
  color: #1F51A2;
}

.additional-option-pack-box-selected-label {
  width: calc(100% + 4px);
  height: 36px;
  font-weight: bold;
  font-size: 1em;
  background-color: #28a745;
  border: 2px solid #28a745;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  left: -2px;
  top: -2px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional-option-pack-box-label {
  color: rgb(96,103,110);
  width: 140px;
  text-align: center;
}

.additional-option-pack-box-price {
  font-size: 1.6em;
  font-weight: bold;
  color: rgb(96,103,110);
  text-align: center;
  margin-top: auto;
}

.additional-option-pack-check-box {
  padding-right: 8px;
}

.vertical { 
  border-left: 1px solid rgb(96,103,110); 
  border-radius: 1px;
  height: 60px; 
  margin-right: 40px;
  margin-left: 40px;
} 

.sprache-container {
  padding-top: 60px;
  padding-right: 0;
  padding-left: 0;
}

.cart-section {
  padding: 60px 90px;
}

.big-price {
  font-size: 2em!important;
}

.bold-price {
  font-weight: bold;
}

.row-separator {
  border-bottom: 1px solid rgb(170,181,193);
  margin-bottom: 16px;
}

.discount-label {
  color: #28a745;
  font-size: 0.8em;
}

.discount-price{
  font-size:2.2em;
}
.discount-price.with{
  color: #28a745;
  font-size:2.4em;
}

.discount-text{
  font-size: 1em;
}
.discount-text.with{
  color: #28a745;
}
.summary-offer-with-discount{
  padding-bottom:2em
}

.offer-left-column {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.offer-right-column {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 400px;
  border-left: 1px solid rgb(170,181,193);
  z-index: 200;
  background-color: #fff;
}

.top-price-box {
  position: absolute;
  top: -67px;
  left: -1px;
  border-left: 1px solid rgb(170,181,193);
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}

.cv-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #1F51A2;
  border-radius: 50%;
  margin-top: 24px;
  margin-bottom: 24px
}

.cv-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.hide-section {
  visibility: hidden;
}

.just-price-star{
  color:rgb(96,103,110);
}

.info-section {
  display: flex;
  width: 262px;
  margin-bottom: 24px;
}

.info-section-icon {
  height: 48px;
  width: 48px;
}

.info-section-details {
  display: flex;
  flex-direction: column;
}

.info-section-details-count {
  display: flex;
  flex-direction: column-reverse;
  font-size: 2em;
  color: rgb(96,103,110);
  height: 48px;
  padding-right: 10px;
}

.info-section-details-count.details{
  height: 48px !important;
}

.info-section-details-count.withdiscout {
  color: #28a745;
  font-weight: bold;
  font-size: 2.4em;
  height: 52px;
}

.info-section-details-count.main-info-price {
  flex-direction: row;
  align-items: center;
}

.info-section-details-label {
  font-size: 0.8em;
  font-weight: bold;
  color: rgb(96,103,110);
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 8px;
}

.info-section-small-details {
  font-size: 0.8em;
  color: rgb(96,103,110);
  width: 200px;
}

.button-order {
  width: 250px;
  height: 48px;
  margin-top: 36px;
  margin-bottom: 36px;
  background-color:  #28a745;
  border-color:  #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-order:hover {
  opacity: 0.8;
  background-color:  #28a745;
  border-color:  #28a745;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
}

.button-link {
  color: #1F51A2;
  font-size: 0.8em;
  text-decoration: underline;
  border: none;
  background-color: #fff;
  margin-top: 24px;
  text-align: right;
}

.empflohen-badge {
  position: absolute;
  z-index: 100;
  right: -82px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 27px;
  z-index: 100;
  align-items: center;
  background-color: #28a745;
  color: #fff;
  text-shadow: 2px 1px 3px rgba(150, 150, 150, 1);
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  width: 349px;
  height: 64px;
  box-shadow: 0px 13px 24px -5px rgba(0,0,0,0.38);
}

.offer-section {
  padding: 60px 90px;
}

.movie-icon {
  height: 124px;
}

.movie-button {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.movie-button-label {
  font-size: 1em;
  font-weight: bold;
  color: #28a745;
  text-align: center;
}

.default-btn-hover:hover {
  opacity: 0.6;
  cursor: pointer;
}

.ihr-angebot {
  font-size: 1em;
  font-weight: bold;
  color: #28a745;
}

.popover {
  min-width: 360px;
}

.popover-body {
  border-radius: 3px!important;
  background-color: #F5821F;
  color: #fff!important;
  font-size: 16px!important;
}

.bs-popover-right>.arrow::before {
  border-right-color: #F5821F;
}

.bs-popover-right>.arrow::after {
  border-right-color: #F5821F;
}

.popover[x-placement^="right"] {
  margin-left: 0!important;
  left: 10px !important;
}
@media (max-width: 575.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .summary-menu {
    width: 80px;
    height: auto;
  }
  .summary-menu-item {
    width: 80px;
    height: 80px;
  }
  .summary-menu-label {
    display:none;
  }
}
@media (min-width: 1200px) {
  .summary-menu {
    width: 200px;
    height: auto;
  }
  .summary-menu-item {
    width: 200px;
    height: 120px;
  }
}

.circle-with-badge {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  border-radius: 50%;
  min-width: 130px;
  width: 130px;
  height: 130px;
  padding: 0;
  text-align:center;
  background: white;
}

.circle-with-badge-bottom-label {
  color: rgb(96,103,110);
}

.custom-circle {
  display: flex;
  align-items: center;
  margin: 0;
  width: 170px;
}

.custom-circle-icon {
  height: 86px;
}

.custom-circle-big-icon {
  height: 124px;
}

.custom-circle-right-label {
  font-size: 2em;
  line-height: 36px;
  font-weight: bold;
  color: #28a745;
}

.custom-big-circle {
  min-width: 180px;
  height: 180px;
}

.carousel-control-area {
  width: 50px;
  opacity: 1;
}

.carousel-section {
  padding: 60px 90px;
}

.section-title {
  font-size: 2em;
  color: rgb(96,103,110);
  padding-top: 24px;
  padding-bottom: 12px;
}

.section-item {
  font-size: 1em;
  color: rgb(96,103,110);
  padding-bottom: 16px;
}

.section-item-disabled {
  opacity: 0.5
}

.summary-menu-item {
  border: 1px solid rgb(170,181,193);
  border-width: 0 0 1px 1px;
  outline: none;
  cursor: pointer;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  text-align: center;
}

.active-menu-button {
  background-color: #1F51A2;
  color: #ffffff !important;
}

.summary-menu-label {
  color: #1F51A2;
  text-align: center;
}

.blue-icon {
  color: #1F51A2 !important;
}

.white-icon {
  color: #ffffff;
}

.grey-icon {
  color: rgb(96,103,110);
  opacity: 0.8
}

.green-icon {
  color: #28a745!important;
}

.limon-icon {
  color: #AFD541;
}

.orange-icon {
  color: #F5821F;
}

.summary-offer-toolbar {
  display: flex;
  width: 100%;
  padding: 13px 15px;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  background-color: #fff;
  margin-right: 80px;
}

.summary-offer-progress-label {
  font-size: 1em;
  font-weight: bold;
  color: #1F51A2;
  padding-right: 15px;
}

.summary-offer-progress {
  width: 30%
}

.summary-offer-progress-bar {
  background-color: #1F51A2;
}

.summary-offer-button {
  width: 186px;
  margin-right: 5px;
  margin-left: 5px;
  font-weight: bold;
}

.summary-offer-icon {
  margin-left: 5px;
}

.video-wrapper {
  padding: 52px;
}

.language-pack-box {
  position: relative;
  width: 23%;
  padding-top: 64px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 48px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  min-width:184px;
  max-width:250px;
}

.language-pack-box-selected {
  border: 2px solid #28a745;
  color: #1F51A2;
}

.language-pack-box-selected-label {
  width: calc(100% + 4px);
  height: 36px;
  font-weight: bold;
  font-size: 1em;
  background-color: #28a745;
  border: 2px solid #28a745;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  left: -2px;
  top: -2px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-pack-box-label {
  color: rgb(96,103,110);
  width: 140px;
  text-align: center;
}

.language-pack-box-price {
  font-size: 2em;
  font-weight: bold;
  color: rgb(96,103,110);
  text-align: center;
  margin-top: auto;
}

.language-pack-check-box {
  padding-right: 8px;
}

.additional-option-pack-box {
  position: relative;
  width: 18%;
  padding-top: 64px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 48px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  min-width:148px;
  max-width:250px;
}

.additional-option-pack-box-selected {
  border: 2px solid #28a745;
  color: #1F51A2;
}

.additional-option-pack-box-selected-label {
  width: calc(100% + 4px);
  height: 36px;
  font-weight: bold;
  font-size: 1em;
  background-color: #28a745;
  border: 2px solid #28a745;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  left: -2px;
  top: -2px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional-option-pack-box-label {
  color: rgb(96,103,110);
  width: 140px;
  text-align: center;
}

.additional-option-pack-box-price {
  font-size: 1.6em;
  font-weight: bold;
  color: rgb(96,103,110);
  text-align: center;
  margin-top: auto;
}

.additional-option-pack-check-box {
  padding-right: 8px;
}

.vertical { 
  border-left: 1px solid rgb(96,103,110); 
  border-radius: 1px;
  height: 60px; 
  margin-right: 40px;
  margin-left: 40px;
} 

.sprache-container {
  padding-top: 60px;
  padding-right: 0;
  padding-left: 0;
}

.cart-section {
  padding: 60px 90px;
}

.big-price {
  font-size: 2em!important;
}

.bold-price {
  font-weight: bold;
}

.row-separator {
  border-bottom: 1px solid rgb(170,181,193);
  margin-bottom: 16px;
}

.discount-label {
  color: #28a745;
  font-size: 0.8em;
}

.discount-price{
  font-size:2.2em;
}
.discount-price.with{
  color: #28a745;
  font-size:2.4em;
}

.discount-text{
  font-size: 1em;
}
.discount-text.with{
  color: #28a745;
}
.summary-offer-with-discount{
  padding-bottom:2em
}

.offer-left-column {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.offer-right-column {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 400px;
  border-left: 1px solid rgb(170,181,193);
  z-index: 200;
  background-color: #fff;
}

.top-price-box {
  position: absolute;
  top: -67px;
  left: -1px;
  border-left: 1px solid rgb(170,181,193);
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}

.cv-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #1F51A2;
  border-radius: 50%;
  margin-top: 24px;
  margin-bottom: 24px
}

.cv-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.hide-section {
  visibility: hidden;
}

.just-price-star{
  color:rgb(96,103,110);
}

.info-section {
  display: flex;
  width: 262px;
  margin-bottom: 24px;
}

.info-section-icon {
  height: 48px;
  width: 48px;
}

.info-section-details {
  display: flex;
  flex-direction: column;
}

.info-section-details-count {
  display: flex;
  flex-direction: column-reverse;
  font-size: 2em;
  color: rgb(96,103,110);
  height: 48px;
  padding-right: 10px;
}

.info-section-details-count.details{
  height: 48px !important;
}

.info-section-details-count.withdiscout {
  color: #28a745;
  font-weight: bold;
  font-size: 2.4em;
  height: 52px;
}

.info-section-details-count.main-info-price {
  flex-direction: row;
  align-items: center;
}

.info-section-details-label {
  font-size: 0.8em;
  font-weight: bold;
  color: rgb(96,103,110);
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 8px;
}

.info-section-small-details {
  font-size: 0.8em;
  color: rgb(96,103,110);
  width: 200px;
}

.button-order {
  width: 250px;
  height: 48px;
  margin-top: 36px;
  margin-bottom: 36px;
  background-color:  #28a745;
  border-color:  #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-order:hover {
  opacity: 0.8;
  background-color:  #28a745;
  border-color:  #28a745;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
}

.button-link {
  color: #1F51A2;
  font-size: 0.8em;
  text-decoration: underline;
  border: none;
  background-color: #fff;
  margin-top: 24px;
  text-align: right;
}

.empflohen-badge {
  position: absolute;
  z-index: 100;
  right: -82px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 27px;
  z-index: 100;
  align-items: center;
  background-color: #28a745;
  color: #fff;
  text-shadow: 2px 1px 3px rgba(150, 150, 150, 1);
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  width: 349px;
  height: 64px;
  box-shadow: 0px 13px 24px -5px rgba(0,0,0,0.38);
}

.offer-section {
  padding: 60px 90px;
}

.movie-icon {
  height: 124px;
}

.movie-button {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.movie-button-label {
  font-size: 1em;
  font-weight: bold;
  color: #28a745;
  text-align: center;
}

.default-btn-hover:hover {
  opacity: 0.6;
  cursor: pointer;
}

.ihr-angebot {
  font-size: 1em;
  font-weight: bold;
  color: #28a745;
}

.popover {
  min-width: 360px;
}

.popover-body {
  border-radius: 3px!important;
  background-color: #F5821F;
  color: #fff!important;
  font-size: 16px!important;
}

.bs-popover-right>.arrow::before {
  border-right-color: #F5821F;
}

.bs-popover-right>.arrow::after {
  border-right-color: #F5821F;
}

.popover[x-placement^="right"] {
  margin-left: 0!important;
  left: 10px !important;
}
.calendar-wrapper{
  display:flex;
  align-items: center;
  justify-content:center;
}
.calendar-wrapper>.react-calendar>.react-calendar__navigation>button{
  background: #FFFFFF;
}
